import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';
import Tooltip from 'ui-kit/tooltip/tooltip';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import FsaHsaCheckbox from 'components/payment-methods/fsa-hsa-checkbox/fsa-hsa-checkbox';

// States
import { ProfileAddPaymentObjectPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    easyRefillAddPaymentCardsRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillPaymetricDetailsRoutine,
    easyRefillTokenizedCardNumberRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    easyRefillEpostPatientNumSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPaymetricDetailsSelector
} from 'state/easy-refill/easy-refill.selectors';

import { creditCardMonthOptions, getExpYears } from 'const/options';

import { PAYMENT_SCHEMA } from 'schema/payment';

// Types
import { EasyRefillPaymetricDetailsPayload } from 'types/easy-refill';
import { Payment, PaymentProps } from 'types/payment';

// Utils
import { detectCardType, getCreditCardEnding, getMonthNum, getNameParts } from 'util/payments';

// Styles
import './easy-refill-payment-form.scss';

declare global {
    interface Window {
        $XIPlugin: {
            createJSRequestPacket: (merchantId: string, accessToken: string) => any;
            ajax: (options: any) => void;
            createField: (arg0: string, arg1: boolean, arg2: any) => any;
        };
    }
}

// DRX-1787: A new form was created due to differences in interfaces between the login
// flow and the unauthorized flow. This decision was made to prevent potential issues.
// TODO: Compare and create a single interface to add payments.

const EasyRefillPaymentForm = ({
    centerFormSubmit = false,
    instructions = 'pages.profile.payment.detailsHeader',
    onCancel,
    showCancel = true,
    submitButtonText = 'pages.profile.payment.labels.savePayment',
    hasPaymentData = true,
    style
}: PaymentProps) => {
    const { t } = useTranslation();
    const formName = 'EasyRefillPaymentForm';
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const expYears = useMemo(() => getExpYears({ dates: 9 }), []);

    const easyRefillPaymetricDetails = useSelector(easyRefillPaymetricDetailsSelector);
    const easyRefillPatientNum = useSelector(easyRefillEpostPatientNumSelector);
    const easyRefillPaymentData = useSelector(easyRefillPaymentCardsSelector);

    useEffect(() => {
        dispatch(easyRefillPaymetricDetailsRoutine.trigger());
        if (!easyRefillPaymentData) dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFormSubmit = async (
        values: ProfileAddPaymentObjectPayload,
        paymetricDetails: EasyRefillPaymetricDetailsPayload
    ) => {
        if (window !== undefined && window.$XIPlugin !== undefined && paymetricDetails !== undefined) {
            //Step 1 - Server side call to get the Access Token from Paymetric.
            const names = values.nameOnCard.split(' ');
            const cardNumber = values.cardNumber;
            const cardData = window.$XIPlugin.createJSRequestPacket(
                paymetricDetails?.merchantId,
                paymetricDetails?.accessToken
            );
            //Step 2 – call the Paymetric script method by sending card number.
            cardData.addField(window.$XIPlugin.createField('SecureCardNumber', true, cardNumber));

            setIsSubmitting(true);

            window.$XIPlugin.ajax({
                //Step 3 – call the Paymetric POST by sending card number along with access token from step 1.
                url: paymetricDetails.serviceUrl + 'Ajax',
                global: false,
                type: 'POST',
                data: cardData,
                success: function () {
                    dispatch(
                        easyRefillTokenizedCardNumberRoutine.trigger({
                            onSuccess: (pciToken: string) => {
                                const creditCard = {
                                    cardActive: true,
                                    cardFirstName: getNameParts(names)?.firstName,
                                    cardLastName: getNameParts(names)?.lastName,
                                    cardMiddleName: getNameParts(names)?.middleName,
                                    defaultCard: !hasPaymentData,
                                    epostPatientNum: easyRefillPatientNum,
                                    cardMonthNum: getMonthNum(values.expMonth),
                                    cardMonthDesc: values.expMonth,
                                    cardNumber: pciToken,
                                    cardSeqNum: '-1',
                                    secureCardNumber: `${getCreditCardEnding(values.cardNumber, '**************')}`,
                                    cardTypeNum: `${detectCardType(values?.cardNumber)?.index}`,
                                    cardType: `${detectCardType(values?.cardNumber)?.key}`,
                                    cardYear: values.expYear.toString(),
                                    pciToken: pciToken,
                                    cardExpiration: `${getMonthNum(values.expMonth)}/${values.expYear.toString()}`,
                                    cardName: values.nameOnCard,
                                    cardIsExpired: false,
                                    forceApproval: true,
                                    fsaCard: values.fsaCard,
                                    securityCode: '',
                                    spendingLimit: '149.99'
                                };
                                dispatch(
                                    easyRefillAddPaymentCardsRoutine({
                                        creditCard: {
                                            ...creditCard
                                        },
                                        onSuccess: () => {
                                            setIsSubmitting(false);
                                            dispatch(
                                                openModal({
                                                    showClose: true,
                                                    contentClassName: 'prescription-add-payment-modal',
                                                    size: 'xl',
                                                    bodyContent: (
                                                        <BirdiModalContent
                                                            icon="default"
                                                            title={t('modals.addPaymentModal.sucess.title')}
                                                            body={t('modals.addPaymentModal.sucess.description')}
                                                        />
                                                    ),
                                                    ctas: [
                                                        {
                                                            onClick: () => {
                                                                dispatch(closeModal({}));
                                                            },
                                                            label: t('modals.addPaymentModal.sucess.cta'),
                                                            variant: 'primary'
                                                        }
                                                    ]
                                                })
                                            );
                                        }
                                    })
                                );
                            },
                            onFailure: () => {
                                dispatch(
                                    openModal({
                                        showClose: false,
                                        type: 'danger',
                                        size: 'lg',
                                        headerContent: (
                                            <BirdiModalHeaderDanger
                                                headerText={t('modals.addPaymentModal.error.title')}
                                                icon="alert"
                                            />
                                        ),
                                        bodyContent: (
                                            <BirdiModalContentAlt
                                                subTitle={t('modals.addPaymentModal.error.description')}
                                            />
                                        ),
                                        ctas: [
                                            {
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                },
                                                label: t('modals.addPaymentModal.error.cta'),
                                                variant: 'primary'
                                            }
                                        ]
                                    })
                                );
                            }
                        })
                    );
                },
                error: function (response: any) {
                    setIsSubmitting(false);
                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addPaymentModal.error.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt subTitle={t('modals.addPaymentModal.error.description')} />
                            ),
                            ctas: [
                                {
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    label: t('modals.addPaymentModal.error.cta'),
                                    variant: 'primary'
                                }
                            ]
                        })
                    );
                }
            });
        }
    };

    const handleFormCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    return (
        <div style={style} data-ga-location="Payment">
            <Row>
                <Col>
                    <Formik<Partial<Payment>>
                        onSubmit={(values) => {
                            handleFormSubmit(values, easyRefillPaymetricDetails);
                        }}
                        validationSchema={PAYMENT_SCHEMA}
                        initialValues={{
                            cardNumber: '',
                            nameOnCard: '',
                            expMonth: '',
                            expYear: '',
                            cvcNumber: '',
                            fsaCard: false
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="payment-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <p className="profile-form-instructions text-left">{t(instructions)}</p>
                                <Row>
                                    <Col>
                                        <Text
                                            label={t('pages.profile.payment.labels.cardNumber')}
                                            name="cardNumber"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.cardNumber
                                                    ? formik.values.cardNumber === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.cardNumber')
                                                          })
                                                        : t('forms.errorMessages.invalidCard')
                                                    : undefined
                                            }
                                            touched={formik.touched.cardNumber}
                                            value={formik.values?.cardNumber}
                                            defaultValue={formik.initialValues?.cardNumber}
                                            maxLength={19}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Text
                                            label={t('pages.profile.payment.labels.nameOnCard')}
                                            name="nameOnCard"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.nameOnCard
                                                    ? formik.values.nameOnCard === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.nameOnCard')
                                                          })
                                                        : t('forms.errorMessages.invalidName')
                                                    : undefined
                                            }
                                            touched={formik.touched.nameOnCard}
                                            value={formik.values?.nameOnCard}
                                            defaultValue={formik.initialValues?.nameOnCard}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={4}>
                                        <Field
                                            name="expMonth"
                                            component={FormSelect}
                                            options={creditCardMonthOptions}
                                            placeholder={t('pages.profile.payment.labels.expMonth')}
                                            errors={
                                                formik.errors?.expMonth
                                                    ? formik.values.expMonth === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.expMonth')
                                                          })
                                                        : t('forms.errorMessages.invalidMonth')
                                                    : undefined
                                            }
                                            touched={formik.touched.expMonth}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Field
                                            name="expYear"
                                            component={FormSelect}
                                            options={expYears}
                                            placeholder={t('pages.profile.payment.labels.expYear')}
                                            errors={
                                                formik.errors?.expYear
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('pages.profile.payment.labels.expYear')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.expYear}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Text
                                            label={t('pages.profile.payment.labels.cvcNumber')}
                                            name="cvcNumber"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.cvcNumber
                                                    ? formik.values.cvcNumber === ''
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.payment.labels.cvcNumber')
                                                          })
                                                        : t('forms.errorMessages.invalidCVC')
                                                    : undefined
                                            }
                                            touched={formik.touched.cvcNumber}
                                            value={formik.values?.cvcNumber}
                                            defaultValue={formik.initialValues?.cvcNumber}
                                            maxLength={4}
                                        />
                                        <div className="tooltip-wrapper">
                                            <Tooltip tip={t('pages.profile.payment.labels.cvcNumberToolTip')} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FsaHsaCheckbox
                                            id="fsaCard"
                                            onClick={(checkboxValue: boolean) =>
                                                formik.setFieldValue('fsaCard', checkboxValue, true)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className={`profile-form-instructions-submit${centerFormSubmit && '-center'}`}>
                                    <Col className={`${centerFormSubmit ? 'text-center' : ''}`}>
                                        <Button
                                            className="sm-full md-full"
                                            label={t(submitButtonText)}
                                            variant="primary"
                                            type="submit"
                                            disabled={!formik.dirty || isSubmitting}
                                            onClick={formik.handleSubmit}
                                            async={isSubmitting}
                                            isBusy={isSubmitting}
                                            dataGAFormName={formName}
                                        />
                                    </Col>
                                    {showCancel && (
                                        <Col
                                            className={`profile-form-instructions${
                                                !centerFormSubmit ? '-cancel' : '-cancel-center'
                                            }`}
                                        >
                                            <Button
                                                className="sm-full"
                                                label={t('pages.profile.payment.labels.cancel')}
                                                variant="text"
                                                type="button"
                                                onClick={handleFormCancel}
                                                dataGAFormName={formName}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            {easyRefillPaymetricDetails?.scriptUrl ? (
                <Helmet>
                    <script id="service-url" src={easyRefillPaymetricDetails.scriptUrl} />
                </Helmet>
            ) : (
                <></>
            )}
        </div>
    );
};

export default EasyRefillPaymentForm;
